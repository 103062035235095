<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';
import Filepond from '@/Components/JobOffers/Employers/Filepond.vue';

const route = inject('route');

const form = useForm({
    name: '',
    email: '',
    tel: '',
    address: '',
    linkedin_url: '',
    cv: null,
    motivation: null,
    message: '',

    accepted: false,
});

const props = defineProps({
    jobOffer: Object,
});

const hasBeenSuccessful = ref(false);

const sendForm = () => {
    form.transform((data) => ({
        ...data,
        cv: {
            name: form.cv[0]?.filename,
            file: form.cv[0]?.file,
        },
        motivation: {
            name: form.motivation[0]?.filename,
            file: form.motivation[0]?.file,
        },
    })).post(route('job-offers.apply', { jobOffer: props.jobOffer.page.slug }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => (hasBeenSuccessful.value = true),
    });
};
</script>

<template>
    <div class="">
        <template v-if="hasBeenSuccessful">
            <div class='min-h-150 pt-50'>

            <h2 class="text-4xl sm:text-6xl font-bold uppercase">
                {{ $t('Thanks for your application!') }}
            </h2>
            <div class="text-2xl sm:text-4xl">
                {{ $t('The employer will contact you shortly.') }}
            </div>

            </div>
        </template>
        <template v-else>
            <span class="hidden mb-8 md:block">
                Deze functie is mogelijk een match, gaaf! Nadat je de onderstaande gegevens hebt ingevuld en op 'versturen' hebt geklikt, wordt jouw sollicitatie verstuurd naar de werkgever. Zij nemen vervolgens contact met jou op.
            </span>
            <FormKit type="form" id="signup-form" @submit="sendForm" :actions="false">
                <FormKit
                    type="text"
                    name="name"
                    :label="'Voor- en achternaam'+'*'" :message-class="'text-white'"
                    v-model="form.name"
                    :errors="form.errors.name"
                    validation="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="email" :message-class="'text-white'"
                    name="email"
                    :label="'E-mailadres'+'*'"
                    validation="required|email"
                    v-model="form.email"
                    :errors="form.errors.email"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="tel" :message-class="'text-white'"
                    name="tel"
                    :label="'Telefoonnummer'+'*'"
                    validation="required|tel"
                    v-model="form.tel"
                    :errors="form.errors.tel"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="text" :message-class="'text-white'"
                    name="address"
                    :label="'Woonplaats'+'*'"
                    v-model="form.address"
                    :errors="form.errors.address"
                    validation="required"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <FormKit
                    type="text" :message-class="'text-white'"
                    name="linkedin"
                    :label="$t('LinkedIn link')"
                    v-model="form.linkedin_url"
                    :errors="form.errors.linkedin_url"
                    :label-class="{
                        'text-blue': false,
                    }"
                />

                <div class="col-span-6">
                    <div class="mb-2">
                        {{ $t('Upload cv') }}
                    </div>
                    <Filepond
                        :accepted-files="'application/pdf, image/jpeg, image/png'"
                        inner-label="Klik hier om je cv te uploaden"
                        v-model="form.cv"
                    ></Filepond>

                    <ul v-if="form?.errors?.cv?.file" class="mt-3 mb-3 list-none p-0 text-xs font-semi text-white">
                        <li class="mb-1 flex items-center gap-2 leading-tight" data-message-type="error">
                            {{ form?.errors?.cv?.file[0] }}
                        </li>
                    </ul>
                </div>

                <div class="col-span-6">
                    <div class="mb-2">
                        {{ $t('Upload motivation letter (highly recommended)') }}
                    </div>
                    <Filepond
                        :accepted-files="'application/pdf, image/jpeg, image/png'"
                        :inner-label="'Klik hier om je motivatiebrief te uploaden'"
                        v-model="form.motivation"
                    ></Filepond>

                    <ul
                        v-if="form?.errors?.motivation?.file"
                        class="mt-3 mb-3 list-none p-0 text-xs font-semi text-white"
                    >
                        <li class="mb-1 flex items-center gap-2 leading-tight" data-message-type="error">
                            {{ form?.errors?.motivation?.file[0] }}
                        </li>
                    </ul>
                </div>

                <FormKit
                    type="textarea" :message-class="'text-white'"
                    name="message"
                    :label="$t('Comment')"
                    v-model="form.message"
                    :errors="form.errors.message"
                    :label-class="{
                        'text-blue': false,
                    }"
                />
                <!--                <FormKit-->
                <!--                    v-model="form.accepted"-->
                <!--                    type="checkbox"-->
                <!--                    :label="-->
                <!--                        $t('I agree with the Terms and conditions and Privacy policy of Flexable and I accept them.')-->
                <!--                    "-->
                <!--                    name="terms"-->
                <!--                    validation="accepted"-->
                <!--                    validation-visibility="dirty"-->
                <!--                />-->

                <p class='text-sm font-medium'>
                    *verplicht veld
                </p>
                <Button
                    :processing="form.processing"
                    blue
                    :recentlySuccessful="form.recentlySuccessful"
                    :text="$t('Send')"
                    class="w-full mt-6"
                />
            </FormKit>
        </template>
    </div>
</template>
