<script setup>
import ButtonLink from '@/Components/Button/Link.vue';
import Icon from '@/Components/Icon.vue';
import Img from '@/Components/Img.vue';
import PublicLayout from '@/Layouts/PublicLayout.vue';
import Checkmark from '@i/checkmark-colourable.svg?raw';
import whiteArrow from '@i/white-arrow.svg';
import { inject, ref } from 'vue';

import JobOfferCard from '@/Components/JobOffers/JobOfferCard.vue';
import JobOfferForm from '@/Components/JobOffers/JobOfferForm.vue';
import Tags from '@/Components/JobOffers/Tags.vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';
import FloatingCta from '@/Components/FloatingCta.vue';
const dayjs = inject('dayjs');

const { t } = useI18n();

const employmentType = { employed: t('Employed'), temp_worker: t('Temp Worker') };
const education = {
    secondary_school: t('Secondary school'),
    secondary_vocational_education: t('Secondary vocational education'),
    higher_vocational_education: t('Higher vocational education'),
    academic_education: t('Academic education'),
};

// Componnets

// Partials
const props = defineProps({
    query: Object,
    jobOffer: Object,
    jobOffers: Object,
});

let filters = ref(true);

</script>

<template>
    <PublicLayout title="FLEXABLE | Job offers">
       <FloatingCta :title="$t('Are you interested?')" :description="$t('You are one application away from your new job!')" :button-text="$t('Apply directly')" link='#apply'></FloatingCta>
        <div class="pt-15 md:py-15 md:min-h-10 bg-gradient-to-r from-pink to-orange relative">
            <div class="container">
                <div class="md:max-w-[50%]">
                    <!-- <a
                        :href="route('public.job_offers.index')"
                        class="flex no-underline uppercase text-white group items-center"
                    >
                        <Icon
                            :icon="whiteArrow"
                            class="pl-5 m-0 transition-all duration-300 group-hover:-translate-x-2 rotate-180"
                        />

                        <span class="truncate text-xl font-semibold">
                            {{ $t('Overview') }}
                        </span>
                    </a> -->

                    <div class="mt-20 mb-20">
                        <h2 class="font-black uppercase text-2xl sm:text-4xl md:text-3xl xl:text-4xl 2xl:text-6xl break-words">{{ jobOffer.function }}</h2>
                        <h2 class="font-semibold uppercase mt-4">{{ jobOffer.company.city }}</h2>
                        <h2 class="font-semibold uppercase mt-1">{{ jobOffer.company.commercial_name }}</h2>
                    </div>
                </div>
            </div>

            <div class="md:absolute h-70 md:h-auto right-0 inset-y-0 md:w-1/2 pl-5 md:pl-0">
                <img :alt="jobOffer.atmospheric_image?.alt_text"
                     :srcset="jobOffer.atmospheric_image?.src_set"
                    :src="jobOffer.atmospheric_image?.src"
                    class="object-cover rounded-tl-[4rem] w-full h-full"
                />
            </div>
        </div>

        <div class="py-10 md:pb-5 md:text-left bg-white">
            <div class="container relative">
                <div class="gap-4 flex flex-col">
                    <Tags :jobOffer="jobOffer"></Tags>

                    <div>
                        <ButtonLink href="#apply" pinkTransparent>
                            {{ $t('Apply directly') }}
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-5 pb-15 md:py-15 md:text-left bg-white text-blue">
            <div class="container relative flex flex-col gap-5">
                <h1 class="text-3xl md:text-6xl font-black uppercase max-w-screen-xl break-words">
                    {{ jobOffer.company.commercial_name }} {{ $t('is looking for an employee for') }}
                    {{ jobOffer.function }}
                </h1>

                <!--                <p>-->
                <!--                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut-->
                <!--                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores-->
                <!--                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.-->
                <!--                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut-->
                <!--                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores-->
                <!--                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.-->
                <!--                </p>-->

                <h2 class="text-3xl font-black uppercase max-w-screen-lg">
                    {{ $t('About the job offer') }}
                </h2>

                <p class='text-lg whitespace-pre-line'>
                    {{ jobOffer.description }}
                </p>
            </div>
        </div>

        <div class="lg:py-10 lg:text-left relative">
            <div class="container flex flex-col lg:flex-row">
                <div class="lg:mr-20 mt-10 lg:mt-0 flex items-center justify-center">
                    <img
                        :src="jobOffer.company.logo?.src" :alt="jobOffer.company.logo?.alt_text"
                        :srcset="jobOffer.company.logo?.src_set"
                        class="object-cover rounded-full w-50 h-50 min-w-50 min-h-50"
                    />
                </div>
                <div class="py-10 lg:py-0">
                    <div class="flex-col gap-5 flex">
                        <h2 class="text-4xl font-black uppercase max-w-screen-lg">
                            {{ jobOffer.company.commercial_name }}
                        </h2>

                        <span class="text-lg max-w-screen-lg min-h-30 whitespace-pre-line">
                            {{ jobOffer.company.description }}
                        </span>
                        <!--                        <div class="flex justify-center lg:justify-start">-->
                        <!--                            <span class="flex no-underline uppercase text-white group items-center">-->
                        <!--                                <span class="truncate text-xl font-black">-->
                        <!--                                    {{ $t('Read more') }}-->
                        <!--                                </span>-->
                        <!--                                <Icon-->
                        <!--                                    :icon="whiteArrow"-->
                        <!--                                    class="pl-5 m-0 transition-all duration-300 group-hover:translate-x-2"-->
                        <!--                                />-->
                        <!--                            </span>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-15 md:text-left bg-white text-blue">
            <div class="container relative flex flex-col">
                <div class="flex flex-col gap-10 rounded-xl">
                    <h1 class="text-3xl font-black uppercase max-w-screen-lg">
                        {{ $t('Main activities') }}
                    </h1>
                    <div v-for="mainActivity in jobOffer.main_activities" class="flex gap-4 md:ml-12">
                        <Icon :icon="Checkmark" class="w-6 h-6 fill-white stroke-white" />
                        <p>
                            {{ mainActivity.title }}
                        </p>
                    </div>

                </div>
            </div>
        </div>

        <div class="py-20 md:py-15 md:text-left bg-white text-blue">
            <div class="container relative grid grid-cols-1 md:grid-cols-2 gap-8">
                <div class="flex flex-col gap-3">
                    <h3 class="text-3xl font-black uppercase max-w-screen-lg text-pink">
                        {{ $t('This is who you are') }}
                    </h3>

                    <div v-for="whoYouAre in jobOffer.who_you_are" class="flex gap-4">
                        <Icon :icon="Checkmark" class="w-6 h-6" />
                        <p>
                            {{ whoYouAre.title }}
                        </p>
                    </div>
                </div>

                <div class="flex flex-col gap-3">
                    <h3 class="text-3xl font-black uppercase max-w-screen-lg text-pink">
                        {{ $t('This is what you get') }}
                    </h3>

                    <div v-for="whatYouGet in jobOffer.what_you_get" class="flex gap-4">
                        <Icon :icon="Checkmark" class="w-6 h-6" />
                        <p>
                            {{ whatYouGet.title }}
                        </p>
                    </div>
                </div>
                <div class="flex flex-col gap-3" v-if='jobOffer.salary || (jobOffer.compensations && jobOffer.compensations.length)'>
                    <h3 class="text-3xl font-black uppercase max-w-lg text-pink">
                        {{ $t('Salary and other compensations') }}
                    </h3>

                    <div class="flex gap-4" v-if='jobOffer.salary'>
                        <Icon :icon="Checkmark" class="w-6 h-6" />

                        <p>{{ jobOffer.salary }}</p>
                    </div>

                    <template v-if='(jobOffer.compensations && jobOffer.compensations.length)'>
                        <div v-for="compensation in jobOffer.compensations" class="flex gap-4">
                            <Icon :icon="Checkmark" class="w-6 h-6" />

                            <p>{{ compensation.title }}</p>
                        </div>
                    </template>
                </div>
                <div class="flex flex-col gap-3">
                    <h3 class="text-3xl font-black uppercase max-w-screen-lg text-pink">{{ $t('Employment') }}</h3>

                    <div class="flex gap-4">
                        <Icon :icon="Checkmark" class="w-6 h-6" />

                        <p>
                            {{ employmentType[jobOffer.employment] }}
                        </p>
                    </div>

                    <div class="flex gap-4">
                        <Icon :icon="Checkmark" class="w-6 h-6" />

                        <p>
                            <span v-if="jobOffer.start_type == 'date'">{{ dayjs(jobOffer.start_date).format('L') }}</span>
                            <span v-else-if="jobOffer.start_type == 'immediately'">Per direct</span>
                            <span v-else-if="jobOffer.start_type == 'consultation'">In overleg</span>

                            <span v-if='jobOffer.end_date'> tot {{ dayjs(jobOffer.end_date).format('L') }}</span>
                        </p>
                    </div>
                </div>
                <div class="md:col-span-2 flex gap-6 flex-col mt-4">
                    <h2 class="text-xl font-black max-w-screen-lg">
                        {{ $t('Are you interested?') }}
                    </h2>


                    <div>
                        <ButtonLink href="#apply" pink>
                            {{ $t('Apply directly') }}
                        </ButtonLink>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-5 bg-gradient-to-r from-pink to-orange">
            <div class="container relative flex flex-col py-20 md:py-15">
                <div id="apply" class="absolute -top-30"></div>

                <h1 class="text-4xl sm:text-6xl xl:text-9xl font-black -mt-27 md:-mt-22 xl:-mt-24 uppercase mb-12">
                    {{ $t('Sollicitate') }}
                </h1>
                <div class="max-w-screen-lg w-full mx-auto text-white">
                    <div>
                        <JobOfferForm :jobOffer="jobOffer"></JobOfferForm>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-15 md:text-left bg-white" v-if="jobOffers.length">
            <div class="container relative">
                <h2 class="mb-15 text-3xl uppercase md:text-4xl md:text-center text-blue font-medium">
                    <span>Meer</span>
                    <span
                    class='font-black'> Vacatures</span>
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <JobOfferCard v-for="jobOffer in jobOffers" :key="jobOffer.id" :jobOffer="jobOffer"></JobOfferCard>
                </div>
            </div>
        </div>
    </PublicLayout>
</template>
